import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import objectUtils from "@/utils/common/objectUtils";
import _ from "lodash-es";

// 함수 초기화
const getUserScopeSession = () =>
  localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
const authCookieWrapper = {
  set: (token) => cookies.set("token", token),
  get: () => cookies.get("token"),
  remove: () => cookies.remove("token"),
};
const setState = (state, userScope) => {
  state.userId = userScope.userId;
  state.loginId = userScope.loginId;
  state.userName = userScope.name;
  state.userType = userScope.userType;
  state.organizationList = userScope.organizationList;
  state.rolePrivilegeList = userScope.rolePrivilegeList;
  state.roleMenuList = userScope.roleMenuList;
  state.activeCustomer = userScope.activeCustomer ?? null;
  state.activeOrganization = userScope.activeOrganization ?? null;
  state.activePrivilegeList = [];
};
const constructorTemplate = () => ({
  userId: null,
  loginId: null,
  userName: null,
  userType: null,
  organizationList: null,
  rolePrivilegeList: null,
  roleMenuList: null,
  activeCustomer: null,
  activeOrganization: null,
  activePrivilegeList: [],
});

// state 초기화
const state = constructorTemplate();

// getters
const USER_ADMIN_TYPE = ["ROLE_SUPER", "ROLE_SYSTEM"];
const getters = {
  userId: (prev) => prev.userId,
  loginId: (prev) => prev.loginId,
  userName: (prev) => prev.userName,
  userType: (prev) => prev.userType,
  isAdmin: (prev) => USER_ADMIN_TYPE.includes(prev.userType),
  organizationList: (prev) => prev.organizationList,
  rolePrivilegeList: (prev) => prev.rolePrivilegeList,
  roleMenuList: (prev) => prev.roleMenuList,
  roleMenuTree: (prev) => {
    const cloneRoleMenuList = _.cloneDeep(prev?.roleMenuList);
    const groupedMenuList = _.groupBy(cloneRoleMenuList, "upperMenuId");
    for (const cloneMenu of cloneRoleMenuList) {
      cloneMenu.children = groupedMenuList[cloneMenu?.menuId] || [];
    }
    const [root] = groupedMenuList[""] || groupedMenuList[null] || [];
    return root;
  },
  activeCustomer: (prev) => prev.activeCustomer,
  activeOrganization: (prev) => prev.activeOrganization,
  isLoggedIn: (prev) =>
    authCookieWrapper.get() &&
    prev.activeOrganization &&
    prev.activeOrganization &&
    prev.activeOrganization.organizationId,
  getUserScopeSession: () => getUserScopeSession(),
  defaultPage: () => "/", // "/": using router default page.
  loginPage: () => "/login.html",
  activePrivilegeList: (prev) => prev.activePrivilegeList,
};

// actions(dispatch)
const actions = {
  fetchLoginSession(context, data) {
    context.commit("setLoginSession", data);
  },
  fetchReplaceLoginSession(context, data) {
    context.commit("replaceLoginSession", data);
  },
  fetchActiveOrganization(context, data) {
    context.commit("setActiveOrganization", data);
  },
  fetchClearLoginSession(context) {
    context.commit("clearLoginSession");
  },
  fetchRoleMenuList(context, data) {
    context.commit("setRoleMenuList", data);
  },
};

// mutations(commit)
const mutations = {
  async clearLoginSession(state) {
    await setState(state, constructorTemplate());
    authCookieWrapper.remove();
    localStorage.clear();
    mutations.setActivePrivilegeList(state);
  },
  setLoginSession(state, { user, scope, token }) {
    setState(state, { ...user, ...scope });
    authCookieWrapper.set(token.token);
    localStorage.setItem("user", JSON.stringify({ user, scope, token }));
    mutations.setActivePrivilegeList(state);
  },
  replaceLoginSession(state, { user, scope, token }) {
    const roleMenuList = objectUtils.deepCopy(state.roleMenuList);
    setState(state, { ...user, ...scope });
    authCookieWrapper.set(token.token);
    localStorage.setItem("user", JSON.stringify({ user, scope, token }));
    mutations.setActivePrivilegeList(state);
    state.roleMenuList = roleMenuList;
  },
  setActiveOrganization(state, data) {
    state.activeOrganization = data;
    const userScopeSession = getUserScopeSession();
    userScopeSession.scope.activeOrganization = data;
    localStorage.setItem("user", JSON.stringify(userScopeSession));
    mutations.setActivePrivilegeList(state);
  },
  setRoleMenuList(state, data) {
    state.roleMenuList = data;
  },
  setActivePrivilegeList(state) {
    if (state.rolePrivilegeList) {
      const organizationRoleId = state.activeOrganization?.roleId ?? "";
      const privilegeIdSet = new Set();
      const privilegeList = state.rolePrivilegeList
        .filter((role) => role.roleId == organizationRoleId)
        .flatMap((role) =>
          role.privilegeList.filter((innerItem) => {
            if (!privilegeIdSet.has(innerItem.privilegeId)) {
              privilegeIdSet.add(innerItem.privilegeId);
              return true;
            }
            return false;
          }),
        );
      state.activePrivilegeList = privilegeList;
    }
  },
};

// session 초기화(Once, for refresh)
if (getUserScopeSession()) {
  const { user, scope } = getUserScopeSession();
  setState(state, { ...user, ...scope });
  mutations.setActivePrivilegeList(state);
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
