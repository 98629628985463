import axios from "axios";
import urlUtils from "@/utils/common/urlUtils";

const headers = {};
const env = import.meta.env;

const getPromise = (method, uri, data = null, options = null) => {
  uri = env.VITE_SERVER_API_URL + uri;
  return axiosRequest(method, uri, data, options);
};

const getCostPromise = (
  method,
  uri,
  data = null,
  options = null,
  useMultiValue = false,
) => {
  uri = env.VITE_COST_SERVER_API_URL + uri;
  return axiosRequest(method, uri, data, options, useMultiValue);
};

const getDummyPromise = (method, uri, data = null, options = null) => {
  uri = env.VITE_FRONT_URL + uri;
  return axiosRequest(method, uri, data, options);
};

const axiosRequest = (
  method,
  uri,
  data = null,
  options = null,
  useMultiValue = false,
) => {
  // if (options == null || options.headers == null || options.headers['X-Location-Pathname'] == null) {
  //   headers['X-Location-Pathname'] = router.currentRoute.path
  // }
  const config = {
    method: method,
    url: uri,
    ...options,
    headers: {
      ...headers,
      ...(options && options.headers),
    },
    withCredentials: true,
  };

  if (method.toUpperCase() === "GET") {
    if (useMultiValue) {
      const params = urlUtils.convertToMultiValueQueryString(data);
      const queryString = Object.values(params).join("&");
      config.url += `?${queryString}`;
    } else {
      config.params = data;
      config.paramsSerializer = (params) => urlUtils.getQueryString(params);
    }
  } else {
    // GET이 아닌 요청에 대한 데이터 처리
    config.data = data;
  }

  return axios(config);
};

export default {
  getPromise,
  getCostPromise,
  getDummyPromise,
};
