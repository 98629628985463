import store from "@/store/index";
import awn from "@/plugins/awesome-notifications";
import objectUtils from "@/utils/common/objectUtils";

const modalSize = {
  XLarge: "modal-xl",
  Large: "modal-lg",
  Small: "modal-sm",
  Medium: "modal-md",
};
const modalFooterBtnType = {
  TYPE1: "confirmAndCancelButton",
  TYPE2: "closeButton",
};
const modalUtils = {
  modalSize,
  modalFooterBtnType,
  handleOpenModal: (
    modalTitle,
    modalName,
    modalData,
    callback = null,
    modalOptions,
  ) => {
    const { dispatch } = store;
    const _modalData = modalData ? objectUtils.deepCopy(modalData) : null;

    const _modalOptions = {
      size: modalSize.Large,
      isScrollable: false,
      ...modalOptions,
    };

    _modalOptions.size = Object.values(modalSize).includes(_modalOptions.size)
      ? _modalOptions.size
      : modalSize.Large;

    dispatch("modal/fetchShowModal", {
      modalTitle: modalTitle,
      modalName: modalName,
      modalData: _modalData,
      callback: callback,
      modalOptions: _modalOptions,
    });
  },
  handleCloseModal: () => {
    const { getters, dispatch } = store;
    const callback = getters["modal/callback"];

    if (callback) {
      callback();
    }

    dispatch("modal/fetchShowModal", {
      modalTitle: "",
      modalName: "",
      modalData: null,
      callback: null,
      modalOptions: "modal-lg",
    });
  },
  handleApiResponse: (response, successMessage, actions, alert) => {
    if (!response.isSuccessful) {
      if (response.resultData.fieldErrors) {
        response.resultData.fieldErrors.forEach((item) => {
          actions.setFieldError(item.field, item.defaultMessage);
        });
      } else {
        alert.isShow = true;
        alert.message = response.resultData.detailMessage;
      }
      return false;
    }
    awn.notifier.success(successMessage);
    modalUtils.handleCloseModal();
    return true;
  },
  handleConfirmation: (
    modalTitle = "",
    onOk = () => {},
    onCancel = false,
    onEscape = () => onOk(), // ESC(Escape) 키 눌렀을 때 콜백 처리
  ) => {
    const modalTitleHTML = ((modalTitle) => {
      const el = document.createElement("div");
      el.classList.add("text-center");
      el.innerHTML = modalTitle;
      return el.outerHTML;
    })(modalTitle);
    // Confirm
    awn.notifier?.confirm(modalTitleHTML, onOk, onCancel);
    // ESC(Escape) 키 눌렀을 때 처리
    document.onkeydown = (event) => {
      if (event.key === "Escape") onEscape();
    };
  },
  getModalData: () => {
    const { getters } = store;
    return getters["modal/modalData"];
  },
};

export default modalUtils;
