const popupUtils = {
  sendDataToParent: (winOpenerRef, sendData) => {
    winOpenerRef.value.postMessage(JSON.stringify(sendData), "*");
  },
  recvDataFromParent: (recvData) => {
    return JSON.parse(recvData);
  },
};

export default popupUtils;
