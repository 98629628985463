/**
 * html2canvas : https://www.npmjs.com/package/html2canvas
 * jspdf : https://www.npmjs.com/package/jspdf
 */
import _ from "lodash";
import awn from "@/plugins/awesome-notifications";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";

const getNowDate = () => {
  /* 파일 이름 설정 : (default) 현재 시간 기준 */
  const now = new Date();
  return `${now.getFullYear()}${
    now.getMonth() + 1
  }${now.getDate()}${now.getHours()}${now.getMinutes()}`;
};

/* 용지 별 규격(mm) */
const paperStandardSize = {
  a1: [594, 841],
  a2: [420, 594],
  a3: [297, 420],
  a4: [210, 297],
  a5: [148, 210],
  a6: [105, 148],
  b1: [788, 1091],
  b2: [788, 545],
  b3: [545, 394],
  b4: [394, 272],
  b5: [272, 197],
  b6: [197, 136],
};

/**
 * 'p' : 세로 portrait, 'l' : 가로 landscape
 */
const paperDirection = {
  l: "landscape",
  p: "portrait",
};

class initOptions {
  _canvasOptions = {
    fileName: "giveMeName.pdf",
    allowTaint: true,
    useCORS: true,
    logging: false,
    height: 0,
    width: 0,
    margin: 30,
    scale: 1.3,
    dpi: 300, //600,
    scrollX: 0,
    scrollY: 0,
    fileExtension: "pdf",
    // pdf 변환할 때, 무시되어야하는 영역이 있다면 pdf-ignore-area 를 id 로 지정하면 된다.
    ignoreElements: function (element) {
      if (element.id === "pdf-ignore-area") {
        return true;
      }
    },
  };

  constructor(canvasOptions) {
    this.canvasOptions = _.merge(this._canvasOptions, canvasOptions);
  }

  get canvasOptions() {
    return this._canvasOptions;
  }

  set canvasOptions(value) {
    this._canvasOptions = _.merge(this._canvasOptions, value);
  }
}

function getPaperSizeName(paperSize) {
  for (const [key, value] of Object.entries(paperStandardSize)) {
    if (value[0] === paperSize[0] && value[1] === paperSize[1]) {
      return key.toUpperCase(); // Returns the paper size name if dimensions match
    }
  }
  return "Unknown"; // Returns 'Unknown' if no match is found
}

function getPaperDirectionName(_paperDirection) {
  if (_paperDirection == paperDirection.l) {
    return "가로";
  } else {
    return "세로";
  }
}

export default {
  paperStandardSize,
  paperDirection,

  /**
   * pdf 다운로드
   *
   * @param {*} element
   * @param {*} paperFormat 종이 포맷 (e.g. 'a4' : A4용지, 'a3' : A3용지, ...)
   * @param {*} paperDirection 종이 방향 (e.g. 'p' : 세로 portrait, 'l' : 가로 landscape)
   * @param {*} options 옵션
   * @returns
   */
  pdfExport: (element, paperFormat, _paperDirection, options) => {
    /* export 할 element Id 미지정 시 return */
    if (element === null || element === undefined) {
      return console.log("DOM ID를 입력해 주세요.");
    }

    /* export 할 element Id 명 */
    const elementDOM = document.querySelector(element);

    const padding = 10; // 캔버스 여백

    /*  기본 옵션  */
    let editedOption = new initOptions(options);

    /* 선택한 element Id의 가로 세로 폭 (캡쳐 이미지 사이즈 지정) */
    editedOption.canvasOptions.width = elementDOM.offsetWidth + padding * 2;
    editedOption.canvasOptions.height = elementDOM.offsetHeight;

    switch (_paperDirection.toLowerCase()) {
      case "l":
        if (
          paperStandardSize[paperFormat][0] > paperStandardSize[paperFormat][1]
        ) {
          editedOption.imgWidth =
            paperStandardSize[paperFormat][0] - padding * 2;
          editedOption.imgHeight = paperStandardSize[paperFormat][1];
        } else {
          editedOption.imgWidth =
            paperStandardSize[paperFormat][1] - padding * 2;
          editedOption.imgHeight = paperStandardSize[paperFormat][0];
        }
        break;
      case "p":
        if (
          paperStandardSize[paperFormat][0] < paperStandardSize[paperFormat][1]
        ) {
          editedOption.imgWidth =
            paperStandardSize[paperFormat][0] - padding * 2;
          editedOption.imgHeight = paperStandardSize[paperFormat][1];
        } else {
          editedOption.imgWidth =
            paperStandardSize[paperFormat][1] - padding * 2;
          editedOption.imgHeight = paperStandardSize[paperFormat][0];
        }
        break;
      default:
        return console.log("용지 방향을 가로(l), 세로(p)로 구분해주세요. ");
    }

    const exportSnapshot = ({ canvas, fileName, type, replaceValue = "" }) => {
      let a = document.createElement("a");
      a.href = canvas.toDataURL(type).replace(type, replaceValue);
      a.download = fileName;
      a.click();
    };

    /* 클라이언트의 성격에 따라 pdf, png 파일을 구분하여 받는 분기문 (기본은 pdf)  */
    switch (editedOption.canvasOptions.fileExtension) {
      case "jpg":
      case "jpeg":
        html2canvas(elementDOM, editedOption.canvasOptions).then((canvas) => {
          const fileName = `${editedOption.canvasOptions.fileName}Jpeg_Export.jpeg`;
          const type = "image/jpeg";
          const replaceValue = "image/octet-stream";
          exportSnapshot({ canvas, fileName, type, replaceValue });
        });
        break;
      case "png":
        html2canvas(elementDOM, editedOption.canvasOptions).then((canvas) => {
          const fileName = `${editedOption.canvasOptions.fileName}PNG_Export.png`;
          const type = "image/png";
          exportSnapshot({ canvas, fileName, type });
        });
        break;
      default: {
        const promise = html2canvas(
          elementDOM,
          editedOption.canvasOptions,
        ).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          let imageWidth =
            canvas.width / (canvas.width / editedOption.imgWidth);
          let imageHeight =
            (editedOption.imgWidth * canvas.height) / canvas.width;

          const pdf = new jsPDF({
            orientation: _paperDirection.toLowerCase(),
            unit: "mm",
            format: paperStandardSize[paperFormat],
            compressPDF: true,
          });

          // 첫페이지 출력 (imgData,    확장자 ,  시작x,   시작y,  폭,     높이)
          pdf.addImage(imgData, "PNG", padding, 0, imageWidth, imageHeight);

          let heightLeft = imageHeight - editedOption.imgHeight;

          while (heightLeft >= 0) {
            let position = heightLeft - imageHeight;

            pdf.addPage();
            pdf.addImage(
              imgData,
              "PNG",
              padding,
              position,
              imageWidth,
              imageHeight,
            );
            heightLeft -= editedOption.imgHeight;
          }
          // 파일 저장
          pdf.save(
            `${editedOption.canvasOptions.fileName}_${getNowDate()}.pdf`,
          );
        });
        awn.notifier.asyncBlock(
          promise,
          null,
          null,
          "다운로드 중입니다.<br/>잠시만 기다려 주세요.",
        );
        break;
      }
    }
  },
  /**
   * pdf 다운로드
   *
   * @param {*} elSelector PDF 변환 영역 Selector (ex: #id, .class)
   * @param {*} filename 파일명
   * @param {*} paperSize 용지 크기 (ex: 'a4' : A4용지, 'a3' : A3용지, ...)
   * @param {*} paperDirection 용지 방향 (ex: 'p' : 세로 portrait, 'l' : 가로 landscape)
   * @param {*} options 옵션
   * @returns
   */
  pdfSave: (
    elSelector,
    filename,
    paperSize,
    _paperDirection,
    customOption = {},
  ) => {
    const elementDOM = document.querySelector(elSelector);

    let margin = 5;
    let initOption = {
      margin,
      filename: "download.pdf",
      image: { type: "jpeg", quality: 1 },
      jsPDF: {
        unit: "mm",
        format: paperSize,
        orientation: _paperDirection,
      },
      html2canvas: {
        scale: 4,
        dpi: 72,
        allowTaint: false,
        logging: false,
        letterRendering: true,
        onclone: (cloneEl) => {
          cloneEl.querySelector(elSelector).style.width =
            paperSize[1] + margin * 2.5;
        },
      },
      pagebreak: { after: ".pdfNextPage" },
      // pagebreak: { mode: "avoid-all", before: "#pdfNextPage" },
      // pagebreak: { mode: ["avoid-all", "css"] },
    };
    const opt = _.merge(initOption, customOption);
    opt.filename = `${filename}_${getPaperSizeName(
      paperSize,
    )}_${getPaperDirectionName(_paperDirection)}.pdf`;

    const promise = html2pdf()
      .set(opt)
      .from(elementDOM)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        console.log(pdf);
        const totalPages = pdf.internal.getNumberOfPages();
        let canvas = pdf.canvas;
        canvas.width = 300;
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          // pdf.setTextColor(150);
          // pdf.text(
          //   `${i}/${totalPages}`,
          //   pdf.internal.pageSize.width / 2 - 10,
          //   5,
          // );
          pdf.text(
            `${i}/${totalPages}`,
            pdf.internal.pageSize.width / 2 - 10,
            pdf.internal.pageSize.height - 5,
          );
        }
      })
      .save();
    awn.notifier.asyncBlock(
      promise,
      null,
      null,
      "다운로드 중입니다.<br/>잠시만 기다려 주세요.",
    );
  },
};
