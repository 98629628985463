export default {
  isBlank: (str) => {
    if (str != undefined) {
      str = str.replace(/\s/g, "");
      return str.length === 0;
    }
    return true;
  },
  isBlankAny: (arr) => {
    let rs = false;
    if (arr != undefined) {
      for (let i = 0; i < arr.length; i++) {
        let str = arr[i];
        if (str == null) {
          rs = true;
          break;
        }
        str = str.replace(/\s/g, "");
        if (str.length === 0) {
          rs = true;
          break;
        }
      }
    }

    return rs;
  },
  isBlankAll: (arr) => {
    let rs = true;
    if (arr != undefined) {
      for (let i = 0; i < arr.length; i++) {
        let str = arr[i];
        if (str) {
          rs = false;
          break;
        }
        str = str.replace(/\s/g, "");
        if (str.length !== 0) {
          rs = false;
          break;
        }
      }
    }
    return rs;
  },
  isValidId: (target) => {
    let regExp = /^[a-z]+[a-z0-9]{5,19}$/g;
    return regExp.test(target);
  },
  isValidPwd: (target) => {
    let regExp =
      /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/;
    return regExp.test(target);
  },
  isCheckPhoneNumber: (target) => {
    let phone = /^01(?:0|1|[6-9])(?:\d{3}|\d{3,4})\d{4}$/;
    return phone.test(target);
  },
  isNumberAny: (arr) => {
    let rs = false;
    let reg = /[^\d]/g;
    if (arr != undefined) {
      for (let i = 0; i < arr.length; i++) {
        if (reg.test(arr[i])) {
          rs = true;
          break;
        }
      }
    }
    return rs;
  },
  isValidPrimaryKey: (str) => {
    let rs;
    if (str != undefined) {
      let reg = /^[a-zA-Z][\w-]+$/gim;
      rs = reg.test(str);
    }
    return rs;
  },
  isValidCode: (target) => {
    /*eslint no-useless-escape: "error"*/
    let regExp = /^[a-zA-Z]+[a-zA-Z0-9_]{1,8}$/g;
    return regExp.test(target);
  },
  isValidMenuId: (target) => {
    /*eslint no-useless-escape: "error"*/
    let regExp = /^[a-zA-Z]+[a-zA-Z0-9_]{1,16}$/g;
    return regExp.test(target);
  },
  isValidIdIncludeBar: (target) => {
    /*eslint no-useless-escape: "error"*/
    let regExp = /^[a-z]+[a-z0-9_-]{5,19}$/g;
    return regExp.test(target);
  },
  isValidIdEmail: (target) => {
    let regExp = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
    return regExp.test(target);
  },
  isValidKor: (target) => {
    let regExp = /^[ㄱ-ㅎ|가-힣]+$/;
    return regExp.test(target);
  },
  isValidResourceId: (target) => {
    let regExp = /^[a-zA-Z]+[a-zA-Z0-9_.-]{1,128}$/g;
    return regExp.test(target);
  },
  extractDownloadFilename: (response) => {
    let disposition = response.headers["content-disposition"];
    let fileName = decodeURI(
      disposition
        .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
        .replace(/['"]/g, "")
    );

    return fileName;
  },
  isValidFileName: (str) => {
    let rs;
    if (str != undefined) {
      let reg = /^[\s0-9a-zA-Zㄱ-힣-_()]+$/gim;
      rs = reg.test(str);
    }
    return rs;
  },
  getExtension(filename) {
    let fileLength = filename.length;
    let dotPosition = filename.lastIndexOf(".");
    let ext = filename.substring(dotPosition + 1, fileLength).toLowerCase();

    return ext;
  },
  stringEscape(str, len) {
    return str?.substring(0, len) + (str.length > len ? "..." : "");
  }
}
