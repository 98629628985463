export default {
  extractNumbersFromString: (inputString) => {
    if (typeof inputString !== "string") {
      return 0;
    }
    const numbersArray = inputString.match(/(-?\d+(\.\d+)?)/g); // inputString.match(/(\d+(\.\d+)?)/g);
    if (!numbersArray) {
      return 0;
    }
    const numbers = numbersArray.map(Number);
    return numbers;
  },
};
