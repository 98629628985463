export default {
  addOrRemoveItem: (array, targetItem) => {
    const exists = array.includes(targetItem);

    if (exists) {
      return array.filter((c) => {
        return c !== targetItem;
      });
    } else {
      const result = array;
      result.push(targetItem);
      return result;
    }
  },
  removeItem: (array, targetItem) => {
    return array.filter((item) => item !== targetItem);
  },
  isEmpty: (array) => {
    return array?.length === 0;
  },
  compareArray: (array1, array2, key) => {
    const array2Key = array2.map((item) => item[key]);
    return array1.filter((item) => !array2Key.includes(item[key]));
  },
  initIndex: (pageInfo, array) => {
    const currentPage = pageInfo;
    const currentPageFirstIndex = (currentPage.page - 1) * currentPage.size + 1;
    array.map((item, index) => {
      item.indexInCurrentPage = currentPageFirstIndex + index;
    });
    return array;
  },
};
