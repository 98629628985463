function cloneObject(obj) {
  let clone = Array.isArray(obj) ? [] : {};
  if (obj) {
    Object.keys(obj).forEach(function (key) {
      if (typeof obj[key] == "object" && obj[key] != null) {
        clone[key] = cloneObject(obj[key]);
      } else {
        clone[key] = obj[key];
      }
    });
  }
  return clone;
}

export default {
  deepCopy: (obj) => {
    return cloneObject(obj);
  },

  getArrayFilterItem: (arr, item, id) => {
    const _id = id ?? "id";
    // prettier-ignore
    return arr.filter((_item) => {return _item[_id] !== item[_id];});
  },
  // arr1의 차집합
  getArrayDifference: (arr1, arr2, id) => {
    const _id = id ?? "id";
    // prettier-ignore
    return arr1.filter((obj1) => !arr2.find((obj2) => obj1[_id] === obj2[_id]));
  },
  getArrayIntersection: (arr1, arr2, id) => {
    const _id = id ?? "id";
    // prettier-ignore
    return arr1.filter((obj1) => arr2.find((obj2) => obj1[_id] === obj2[_id]));
  },
  getFlatObject: (object, prefix = "") => {
    return Object.keys(object).reduce((carry, key) => {
      const pre = prefix ? prefix + `.${key}` : "";

      if (Array.isArray(object[key])) {
        carry = object[key].reduce((array, value, index) => {
          array[(pre || key) + `[${index}]`] = value;
          return array;
        }, carry);
      } else if (object[key] && typeof object[key] === "object") {
        Object.assign(carry, this.getFlatObject(object[key], pre || key));
      } else {
        carry[pre || key] = object[key];
      }

      return carry;
    }, {});
  },
};
