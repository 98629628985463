import objectUtils from "@/utils/common/objectUtils";

export default {
  getQueryString: (params) => {
    const queryString = new URLSearchParams();
    for (const [key, value] of Object.entries(
      objectUtils.getFlatObject(params),
    )) {
      if (value !== null && value !== undefined && value !== "") {
        queryString.append(key, value);
      }
    }
    return queryString.toString();
  },
  /*
   * 주어진 라우트 경로 목록에서 중복을 제거하고 필터링된 최종 경로를 반환
   * @param {Array<string>} matchedRoutePathList - 라우트 경로 목록
   * @returns {string | undefined} - 필터링된 최종 경로
   */
  filteredRoutePath: (matchedRoutePathList) => {
    return matchedRoutePathList.find((path, i) => {
      for (let j = 0; j < matchedRoutePathList.length; j++) {
        // 현재 경로를 제외한 다른 경로가 현재 경로를 포함하는 경우 중복으로 판단
        if (i !== j && matchedRoutePathList[j].includes(path)) {
          return false; // 중복이 발견되면 해당 경로를 필터링
        }
      }
      // 중복이 발견되지 않으면 현재 경로를 유지
      return true;
    });
  },

  /*
   * GET 요청에서
   * 쿼리 파라미터를 배열로 넘기는데 중복된 Key가 필요할 경우 사용
   * => 기존 getQueryString() 사용 시 => Project[0]=id123&Project[1]=id124
   * => convertToMultiValueQueryString => Project=id123&Project=id124
   */

  convertToMultiValueQueryString: (data) => {
    const transformedData = {};

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key];

        // 배열인 경우 쿼리 문자열 형태로 변환
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (transformedData[`${key}`]) {
              transformedData[`${key}`] += `&${key}=${item}`;
            } else {
              transformedData[`${key}`] = `${key}=${item}`;
            }
          });
        } else {
          if (value) {
            transformedData[key] = `${key}=${value}`;
          }
        }
      }
    }
    return transformedData;
  },
};
