// state
const state = () => ({
  modalTitle: "",
  modalName: "",
  modalData: {},
  modalOptions: "",
  callback: function () {},
});

// getters
const getters = {
  modalTitle: (prev) => prev.modalTitle,
  modalName: (prev) => prev.modalName,
  modalData: (prev) => prev.modalData,
  modalOptions: (prev) => prev.modalOptions,
  callback: (prev) => prev.callback,
};

// actions(dispatch)
const actions = {
  fetchShowModal(
    context,
    { modalTitle, modalName, modalData, callback, modalOptions },
  ) {
    context.commit("setShowModal", {
      modalTitle,
      modalName,
      modalData,
      callback,
      modalOptions,
    });
  },
};

// mutations(commit)
const mutations = {
  setShowModal(
    state,
    { modalTitle, modalName, modalData, callback, modalOptions },
  ) {
    setState(state, {
      modalTitle,
      modalName,
      modalData,
      callback,
      modalOptions,
    });
  },
};

const setState = (
  state,
  { modalTitle, modalName, modalData, callback, modalOptions },
) => {
  state.modalTitle = modalTitle;
  state.modalName = modalName;
  state.modalData = modalData;
  state.callback = callback;
  state.modalOptions = modalOptions;
  // state.modalSize = ["modal-xl", "modal-lg", "modal-sm"].includes(modalSize)
  //   ? modalSize
  //   : state.modalSize;
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
