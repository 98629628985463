export default {
  getPageInfo: (opt) => {
    const { page, size, sort } = { page: 1, size: 10, sort: null, ...opt };
    const pageInfo = {
      page: page ?? 1,
      size: size ?? 10,
      sort: sort ?? null,
      totalPage: 0,
      totalSize: 0,
    };
    return pageInfo;
  },
};
