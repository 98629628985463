import AWN from "awesome-notifications";
import "awesome-notifications/dist/style.css";

const globalOptions = {
  position: "bottom-right",
  labels: {
    tip: "팁",
    info: "정보",
    success: "성공",
    alert: "오류",
    warning: "주의",
    async: "로딩중",
    confirm: "알림",
    confirmOk: "확인",
    confirmCancel: "취소",
  },
  messages: {
    tip: "관리자에게 문의해 주세요.",
    info: "",
    success: "저장되었습니다.",
    warning: "",
    alert: "",
    async: "",
    confirm: "",
    "async-block": "진행중",
  },
  maxNotifications: 2, // 토스트할 수 있는 최대량
  durations: {
    global: 6000,
  },
};

var awn = new AWN(globalOptions);

export default {
  install(app) {
    app.config.globalProperties.$awn = awn;
  },
  notifier: awn, // js 파일에서 awn.notifier.success()로 호출
};
