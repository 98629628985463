import { createStore, createLogger } from 'vuex';
import userStore from '@/store/modules/userStore';
import modalStore from '@/store/modules/modalStore';

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

export default createStore({
  plugins,
  modules: {
    user: userStore,
    modal: modalStore,
  },
});
