export default {
  /**
   * 파일내용 다운로드
   * @param {string} fileName 파일명
   * @param {string} textData 파일내용
   * @param {string} fileExtension 파일 확장자 (옵션)
   */
  downloadTextData: (fileName, textData, fileExtension) => {
    fileName = decodeURI(fileName);

    if (fileExtension && fileExtension.trim() !== "") {
      fileExtension = fileExtension.trim();
    } else {
      fileExtension = "txt"; // yaml, json etc..
    }
    fileName += `.${fileExtension}`;

    let blob = new Blob([textData], {
      type: "text/plain;charset=utf-8",
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.display = "none";
    document.body.appendChild(link);

    link.click();
    link.remove();
  },
  downloadRowDataToCsv: (fileName, rowData) => {
    fileName = decodeURI(fileName);
    fileName += `.csv`;

    // const rowData = [
    //   {
    //     firstName: "Idorenyin",
    //     lastName: "Udoh",
    //   },
    //   {
    //     firstName: "Loyle",
    //     lastName: "Carner",
    //   },
    //   {
    //     firstName: "Tamunotekena",
    //     lastName: "Dagogo",
    //   },
    // ];

    const titleKeys = Object.keys(rowData[0]);

    const refinedData = [];
    refinedData.push(titleKeys);

    rowData.forEach((item) => {
      refinedData.push(Object.values(item));
    });

    let csvContent = "";

    refinedData.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });

    const blob = new Blob(["\ufeff" + csvContent], {
      type: "text/csv;charset=utf-8,",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.display = "none";
    document.body.appendChild(link);

    link.click();
    link.remove();
  },
};
