import store from "@/store/index";
import { useCookies } from "vue3-cookies";
import api from "./http";
import modalUtils from "@/utils/common/modalUtils";

const { cookies } = useCookies();
const LOGIN_URL = "/login";
const TOKEN_REFRESH_URL = "/portal/token/refresh";
const EXCLUDE_URL_LIST = [TOKEN_REFRESH_URL, LOGIN_URL];
const LOG_IN_RETRY = (resultData) => {
  store.dispatch("user/fetchLoginSession", resultData).then(() => {
    window.location.reload(); // 현재 페이지의 모든 endpoint 재호출을 위해 페이지 refresh
  });
};
const LOG_OUT = () => {
  store
    .dispatch("user/fetchClearLoginSession")
    .then(() => (window.location = store.getters["user/loginPage"]));
  throw "NOT Found Authentication.";
};

// 파일 갯수 및 파일 크기 제한 체크
function validFile(config) {
  let result = "";
  const error = {};
  if (config.data instanceof FormData) {
    const validConfig = {
      maxFileUnit: "MB", // 최대 파일 크기 unit
      maxFileUnitSize: 1024 * 1024, // 최대 파일 크기 unit size
      maxFileSize: 5, // 최대 파일 크기
      maxFileCount: 5, // 최대 파일 갯수
      duplicateFileKeyCheck: [], // 파일 key 중복 checker
      fileCount: 0, // 현재 파일 갯수
    };
    const configDataKeys = config.data.keys();
    for (const formDataKey of configDataKeys) {
      if (validConfig.duplicateFileKeyCheck.includes(formDataKey)) {
        continue;
      }
      validConfig.duplicateFileKeyCheck.push(formDataKey);
      const formdatas = config.data.getAll(formDataKey);
      for (const formdata of formdatas) {
        if (formdata instanceof File) {
          validConfig.fileCount++;
          const file = formdata;
          if (
            file.size >
            validConfig.maxFileSize * validConfig.maxFileUnitSize
          ) {
            if (!error["fileSizeError"]) {
              // prettier-ignore
              error['fileSizeError'] = [`최대 파일 크기를 초과하였습니다.(최대: ${validConfig.maxFileSize} ${validConfig.maxFileUnit})`];
            }
            // prettier-ignore
            error.fileSizeError.push(`- ${file.name} (${(file.size / validConfig.maxFileUnitSize).toFixed(1)} ${validConfig.maxFileUnit})`);
          }
        }
      }
    }
    if (validConfig.maxFileCount < validConfig.fileCount) {
      if (!error["fileCountError"]) {
        // prettier-ignore
        error['fileCountError'] = [`최대 파일 갯수를 초과하였습니다.(최대: ${validConfig.maxFileCount} 개)`];
      }
      // error.push(`- 최대 파일 갯수를 초과하였습니다.(최대: ${validConfig.maxFileCount} 개, 현재: ${validConfig.fileCount})`);
      error.fileCountError.push(`- 현재: ${validConfig.fileCount}`);
    }
  }
  var keys = Object.keys(error);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    result += error[key].join("<br/>");
    if (i + 1 < keys.length) {
      result += "<br/><br/>";
    }
  }
  return result;
}
export function interceptRequest(axios) {
  axios.interceptors.request.use(
    function (config) {
      const isLoggedIn =
        store.getters["user/isLoggedIn"] && cookies.get("token");
      const isLogInPath = new URL(config.url).pathname === LOGIN_URL;
      // state(mutate) true 체크 및 토큰 직접 체크
      if (isLoggedIn) {
        config.headers["Authorization"] = `Bearer ${cookies.get("token")}`;
        config.headers["X-ACTIVE-ORGANIZATION-ID"] =
          store.getters["user/activeOrganization"].organizationId;
        // config.headers.common['Authorization'] = `Bearer eyJhwiCJIUzI1NiJ9.eyJzdWIiOiIyMTAwMDAwMSIGdnBhc3N3b3JkIjoiODMwNzA2MjAwMCIsImlhdCI6MTYyOTQyMDQ1MX0.4ptVqb_6dKlDAAxj28D95K07EEwsX1F8HpfzAG73Vpw`

        const fileErrMsg = validFile(config);
        if (fileErrMsg) {
          modalUtils.handleConfirmation(fileErrMsg, null);
          throw fileErrMsg;
        }

        return config;
      } else if (isLogInPath) {
        return config;
      } else {
        LOG_OUT();
      }
    },
    function (error) {
      return Promise.reject(error);
    },
  );
}
export function interceptResponse(axios /* ,funcAlert */) {
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      const requestHeaders = response.config?.headers;
      if (requestHeaders?.get("x-file-type")) {
        const responseHeaders = response.headers;
        const contentDisposition = responseHeaders?.get("content-disposition");
        if (contentDisposition?.startsWith("attachment")) {
          const file = {
            name: contentDisposition.replace(
              /(.*filename=["'](.*?)["'])/,
              "$2",
            ),
            content: response.data,
          };
          return Promise.resolve(file);
        }
      }
      return Promise.resolve(response.data);
    },
    async function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      const originalRequest = error.config;
      if (error.config && error.response) {
        const { status, data } = error.response;
        const getDataErrorMessage = () => data?.resultData?.error?.message;
        const getDataErrorCode = () => data?.resultData?.error?.code;
        const getDataErrorCodeAndStatus = () => {
          const { statusValue, code } = data?.resultData?.error || {};
          if (statusValue && code) {
            return `${statusValue}, ${code}`;
          }
          return status;
        };
        switch (status) {
          case 401: {
            // 401(UNAUTHORIZED): token expired 시 재조회
            if (
              !originalRequest._retry &&
              !EXCLUDE_URL_LIST.includes(new URL(error.config.url).pathname)
            ) {
              const getUserScopeSession =
                store.getters["user/getUserScopeSession"];
              const refreshToken = getUserScopeSession.token?.refreshToken;
              if (refreshToken) {
                originalRequest._retry = true;
                const response = await api
                  .getPromise("POST", TOKEN_REFRESH_URL, {
                    refreshToken,
                  })
                  .catch(() => {
                    // 제어 못하는 에러인 경우
                    LOG_OUT();
                  });
                const resultData = response.resultData;
                if (resultData.error) {
                  // 제어하는 에러인 경우
                  LOG_OUT();
                } else {
                  LOG_IN_RETRY(resultData);
                }
              }
            }
            break;
          }
          case 403:
          case 404: {
            // 403(FORBIDDEN), 404(NOT_FOUND)
            const isLogoutError = [
              "NO_ORGANIZATION_MEMBERSHIP", // 소속 조직 없음
              "CANNOT_ACCESS_ACTIVE_ORGANIZATION", // Active 조직에 접근 불가
            ].includes(getDataErrorCode());
            let modalTitle = (() => {
              let message = getDataErrorMessage();
              return isLogoutError
                ? `${message}<br>`
                : `${message}<br>확인을 누르면 첫 페이지로 이동합니다.<br>`;
            })(isLogoutError);
            modalTitle += `(오류코드: ${getDataErrorCodeAndStatus()})`;

            modalUtils.handleConfirmation(modalTitle, () => {
              if (isLogoutError) {
                LOG_OUT();
              } else {
                window.location = "/";
              }
            });
            break;
          }
          case 405: {
            // 405(METHOD_NOT_ALLOWED)
            let modalTitle =
              "오류가 발생했습니다.<br>잠시 후 다시 시도해 주세요.<br>";
            modalTitle += `(오류코드: ${getDataErrorCodeAndStatus()})`;

            modalUtils.handleConfirmation(modalTitle);
            break;
          }
          case 500: {
            // 500(SERVER_ERROR)
            let modalTitle =
              "오류가 발생했습니다.<br>잠시 후 다시 시도해 주세요.<br>";
            modalTitle += `(오류코드: ${getDataErrorCodeAndStatus()})`;

            modalUtils.handleConfirmation(modalTitle);
            break;
          }
          default: {
            // 확인하지 못한 에러인 경우
            let modalTitle =
              "오류가 발생했습니다.<br>담당자에게 연락바랍니다.<br>";
            modalTitle += `(오류코드: ${getDataErrorCodeAndStatus()})`;

            modalUtils.handleConfirmation(modalTitle, LOG_OUT());
            break;
          }
        }
      }
      return Promise.reject(error);
    },
  );
}
