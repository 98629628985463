export default {
  format: {
    TYPE1: "YYYY-MM-DD HH:mm",
    TYPE2: "YYYY-MM-DD HH:mm:ss",
  },
  calculateElapsedTime: (startTime, endTime) => {
    if (!endTime) {
      return "-";
    }

    const startDateTime = new Date(startTime);
    const endDateTime = new Date(endTime);
    const elapsedTimeInMilliseconds = endDateTime - startDateTime;

    const pad = (value) => value.toString().padStart(2, "0");

    const hours = Math.floor(elapsedTimeInMilliseconds / 3600000);
    const minutes = Math.floor((elapsedTimeInMilliseconds % 3600000) / 60000);
    const seconds = Math.floor((elapsedTimeInMilliseconds % 60000) / 1000);

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  },
  millisecondsToTime: (milliseconds) => {
    // Convert milliseconds to seconds
    let seconds = Math.floor(milliseconds / 1000);

    // Calculate hours, minutes, and remaining seconds
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    const padZero = (num) => {
      return (num < 10 ? "0" : "") + num;
    };

    // Format the time components
    let formattedTime = [
      padZero(hours),
      padZero(minutes),
      padZero(remainingSeconds),
    ].join(":");

    return formattedTime;
  },

  /**
   * 특정 월의 날짜 범위 조회
   *
   * @param {string} yearMonth YYYY-MM (예: 2024-03)
   * @returns {string} YYYY-MM-DD ~ YYYY-MM-DD (예: 2024-03-01 ~ 2024-03-31)
   */
  getDateRangeFromMonth(yearMonth) {
    if (!yearMonth) {
      return "";
    }

    const [year, month] = yearMonth.split("-").map(Number);
    const firstDay = new Date(year, month - 1, 1);
    const lastDay = new Date(year, month, 0);

    // YYYY-MM-DD 포맷
    const formatDate = (date) => {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0",
      )}-${String(date.getDate()).padStart(2, "0")}`;
    };
    return `${formatDate(firstDay)} ~ ${formatDate(lastDay)}`;
  },
};
