const modules = import.meta.glob("./*.js", { eager: true }); // 동일 디렉토리 *.js

export default {
  install: (app) => {
    Object.entries(modules).forEach(([path, module]) => {
      const moduleName = path.replace(/^.+\/([^/]+)\.js$/, "$1"); // ./downloadUtils.js -> downloadUtils
      app.config.globalProperties[`$${moduleName}`] = module.default;
    });
  },
};

// import stringUtils from "@/utils/stringUtils";
// import numberUtils from "@/utils/numberUtils";
// import pageUtils from "@/utils/pageUtils";
// import objectUtils from "@/utils/objectUtils";
// import urlUtils from "@/utils/urlUtils";
// import modalUtils from "@/utils/modalUtils";
// import dateUtils from "@/utils/dateUtils";
// import arrayUtils from "@/utils/arrayUtils";
// import downloadUtils from "@/utils/downloadUtils";

// export default {
//   install: (app) => {
//     app.config.globalProperties.$stringUtils = stringUtils;
//     app.config.globalProperties.$numberUtils = numberUtils;
//     app.config.globalProperties.$pageUtils = pageUtils;
//     app.config.globalProperties.$objectUtils = objectUtils;
//     app.config.globalProperties.$urlUtils = urlUtils;
//     app.config.globalProperties.$modalUtils = modalUtils;
//     app.config.globalProperties.$dateUtils = dateUtils;
//     app.config.globalProperties.$arrayUtils = arrayUtils;
//     app.config.globalProperties.$downloadUtils = downloadUtils;
//   },
// };
